#footer-top {
  padding: 2% 0;
}
#footer-top .col-xs-4 {
  margin: 2% auto;
}
#footer-top .col-xs-4 .footer-logo-container img {
  width: 100%;
}
#footer-bottom-desktop {
  background-color: #2e373c;
  color: #fff;
  height: 15em;
  padding: 2% 5% 10% 5%;
}
@media (max-width: 840px) {
  #footer-bottom-desktop {
    display: none;
  }
}
#footer-bottom-desktop .main-section {
  border-color: #2e373c;
}
#footer-bottom-desktop .main-section .nav.navbar-nav {
  margin-top: 2em;
}
#footer-bottom-desktop .main-section .nav.navbar-nav .divider {
  height: 1em;
  width: 2px;
  background-color: #ffc600;
  margin: 12% 0 0 0;
}
#footer-bottom-desktop .main-section .nav.navbar-nav .active a {
  color: #ffc600;
}
#footer-bottom-desktop .main-section .nav.navbar-nav a {
  color: #fff;
  background: transparent;
  line-height: 5.5;
}
#footer-bottom-desktop .main-section .nav.navbar-nav a:hover {
  color: #ffc600;
}
#footer-bottom-desktop .footer-image-container {
  width: 8em;
  height: 8em;
  background-size: cover;
  background-position: center;
}
#footer-bottom-desktop .navbar-right p {
  font-size: 10px;
  opacity: 0.8;
  padding: 4% 0;
}
#footer-bottom-mobile {
  background-color: #2e373c;
  color: #fff;
  padding: 5% 5% 10% 5%;
}
@media (min-width: 840px) {
  #footer-bottom-mobile {
    display: none;
  }
}
#footer-bottom-mobile .footer-image-container {
  width: 12em;
  height: 12em;
  margin: 0 auto;
  background-position: center;
  background-size: cover;
}
#footer-bottom-mobile .footer-image-container img {
  width: 100%;
}
#footer-bottom-mobile .footer-follow-icons {
  text-align: center;
  margin: 2% auto 1% auto;
}
#footer-bottom-mobile .footer-follow-icons i {
  font-size: 36px;
  margin: 1% 3%;
}
#footer-bottom-mobile p {
  font-size: 14px;
  text-align: center;
  margin-top: 2%;
}
