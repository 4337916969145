#cf-home {
  background: #f5f5f5;
  color: #8d8d8d;
}
#cf-home .title-section {
  text-align: center;
  margin: 3% 0 4% 0;
}
#cf-home .inner {
  padding: 0 5%;
}
#cf-home .inner label {
  color: #267788;
  font-size: 12px;
}
#cf-home .inner .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $dark-grey;
  border-radius: 0;
  box-shadow: none;
  --webkit-boxshadow: none;
  background: none;
}
