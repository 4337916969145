#uni-nav {
  background-color: #000;
}
@media (max-width: 850px) {
  #uni-nav {
    display: none;
  }
}
#uni-nav .row {
  padding: 5px 0;
}
#uni-nav .row a {
  text-decoration: none;
  color: #fff;
}
#uni-nav .row a p {
  margin: 0 auto;
  font-size: 11px;
}
#uni-nav .row a p:hover {
  color: #ffc600;
  cursor: default;
}
