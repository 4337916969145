#featured-projects {
  margin: 2% auto;
}
#featured-projects h4 {
  color: #8d8d8d;
  margin-bottom: 2%;
}
#featured-projects .swiper-container {
  height: 20em;
}
#featured-projects .swiper-container a {
  text-decoration: none;
}
#featured-projects .swiper-container .swiper-slide {
  text-align: center;
  height: 75%;
  width: 90%;
}
#featured-projects .swiper-container .swiper-slide:hover > .project-title {
  opacity: 1;
}
#featured-projects .swiper-container .swiper-slide:hover > .image-overlay {
  opacity: 0.6;
}
#featured-projects .swiper-container .swiper-slide img {
  height: 100%;
  width: 100%;
}
#featured-projects .swiper-container .swiper-slide .project-title {
  font-family: "Nudista-bold", sans-serif;
  position: absolute;
  top: 35%;
  left: 20%;
  right: 20%;
  font-size: 24px;
  color: #fff;
  opacity: 0;
}
#featured-projects .swiper-container .swiper-pagination {
  bottom: -2px;
}
