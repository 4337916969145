.topImage img {
  width: 100%;
}
#project-content-section h5 {
  text-align: left;
  color: #267788;
  font-size: 16px;
}
#project-content-section h2 {
  margin: 1.5% auto;
}
#project-content-section h2 p {
  opacity: 0.8;
  color: #8d8d8d;
}
#project-content-section .project-top {
  margin: 1.5% auto;
}
#project-content-section .project-top h4 {
  display: inline-block;
}
#project-content-section .project-top .category-info .project-categories {
  display: inline-block;
  margin: 0 5% 0 0;
  height: 100%;
  float: right;
}
@media (max-width: 991px) {
  #project-content-section .project-top .category-info .project-categories {
    float: left;
  }
}
#project-content-section .project-top .category-info .project-categories div {
  display: inline-block;
}
#project-content-section .project-top .category-info .project-categories div p {
  color: #267788;
}
#project-content-section .project-top .category-info .project-categories-icons {
  display: inline-block;
  height: 100%;
}
#project-content-section .project-side {
  width: 65%;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #project-content-section .project-side {
    margin: 2% 0;
  }
}
#project-content-section .project-side .more-info-section {
  background: #2e373c;
  padding: 4% 7%;
}
#project-content-section .project-side .more-info-section .divider {
  width: 100%;
  height: 1px;
  margin: 2% auto;
  background: #ffc600;
}
#project-content-section .project-side .more-info-section .connect a {
  text-decoration: none;
  display: block;
  color: #fff;
  margin-bottom: 2%;
}
#project-content-section .project-side .more-info-section .connect a div {
  display: inline-flex;
  margin-right: 1%;
}
#project-content-section .project-side .more-info-section .cast p {
  display: inline-block;
  font-size: 13px;
  color: #fff;
}
.no-padding .project-back-button {
  width: 100%;
  background: #267788;
  padding: 2% 0;
}
.no-padding .project-back-button div {
  position: relative;
  width: 25%;
  margin: 0 auto;
  text-align: center;
}
.no-padding .project-back-button div:hover a {
  left: 0;
}
.no-padding .project-back-button div:hover span {
  opacity: 1;
}
.no-padding .project-back-button div a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  position: relative;
  left: 5px;
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}
.no-padding .project-back-button div span {
  color: #fff;
  margin: 1% 0 0 1%;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
