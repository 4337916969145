.news-section {
  margin-bottom: 2%;
}
.news-section .inner {
  padding: 2% 0;
}
.news-section .inner h4 {
  color: #8d8d8d;
  text-align: center;
  margin-bottom: 2%;
}
.news-section .inner .col-xs-12 {
  margin: 0 0 2% 0;
}
.news-section .inner .col-xs-12 a {
  text-decoration: none;
}
.news-section .inner .col-xs-12 .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
.news-section .inner .col-xs-12 .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.news-section .inner .col-xs-12 .card .image-container img {
  width: 100%;
}
.news-section .inner .col-xs-12 .card .card-block {
  background: #2e373c;
  padding: 2% 7%;
  min-height: 12em;
  max-height: 14em;
}
.news-section .inner .col-xs-12 .card .card-block .card-text {
  color: #fff;
}
.news-section .inner .col-xs-12 .card .card-block .card-date {
  color: #ffc600;
  font-size: 12px;
}
