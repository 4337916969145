#project-landing h4 {
  margin: 2% auto;
  color: #8d8d8d;
  text-align: center;
}
#project-landing a {
  text-decoration: none;
}
#project-landing .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
#project-landing .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
#project-landing .card .image-container img {
  width: 100%;
}
#project-landing .card .card-block {
  padding: 5%;
}
#project-landing .card .card-block .card-title {
  font-family: "Nudista-semi-bold";
  font-size: 18px;
  margin-bottom: 2%;
}
#project-landing .card .card-block .project-categories {
  margin-bottom: 2%;
}
#project-landing .card .card-block .project-categories span {
  color: #ffc600;
  font-size: 12px;
}
