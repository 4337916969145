#carousel-container {
  padding: 0;
}
#carousel-container .swiper-container a {
  text-decoration: none;
}
#carousel-container .swiper-container .swiper-slide:hover > .carousel-caption {
  color: #fff;
}
#carousel-container .swiper-container .swiper-slide .carousel-caption p {
  color: #fff;
}
@media (max-width: 600px) {
  #carousel-container .swiper-container .swiper-slide .carousel-caption a {
    display: none;
  }
}
#carousel-container .swiper-container .image-container {
  height: auto;
  width: 100%;
}
#carousel-container .swiper-container .image-container img {
  width: 100%;
  position: relative;
  z-index: 1;
}
#carousel-container .swiper-container .swiper-pagination-bullet {
  background-color: #fff;
  opacity: 1;
}
#carousel-container .swiper-container .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffc600;
}
