.single-news-section {
  padding-top: 3%;
}
.single-news-section h1 {
  color: #2e373c;
  margin-bottom: 2%;
}
.single-news-section p {
  opacity: 0.8;
}
.single-news-section .image-container-article {
  margin-bottom: 3%;
}
.single-news-section .image-container-article img {
  width: 100%;
}
.single-news-section .recent-posts-single h3 {
  color: #8d8d8d;
  text-align: left;
  margin: 5% 0;
}
.single-news-section .recent-posts-single .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
.single-news-section .recent-posts-single .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.single-news-section .recent-posts-single .card .image-container img {
  width: 100%;
}
.single-news-section .recent-posts-single .card .card-block {
  background: #2e373c;
  padding: 2% 7%;
}
.single-news-section .recent-posts-single .card .card-block .card-text {
  color: #fff;
}
.single-news-section .recent-posts-single .card .card-block .card-date {
  color: #ffc600;
  font-size: 12px;
}
.single-news-section .mobile-recent .recent-posts-single h3 {
  text-align: center;
}
.single-news-section .mobile-recent .recent-posts-single .card {
  width: 80%;
  margin: 0 auto 8% auto;
}
