#bio-page {
  padding: 4% 10%;
}
#bio-page h3,
#bio-page h4,
#bio-page h6 {
  color: #8d8d8d;
}
#bio-page h3 {
  margin-bottom: 2%;
}
#bio-page h2 {
  color: #2e373c;
}
#bio-page .name {
  margin-bottom: 4%;
}
#bio-page .bio-summary-section {
  padding: 0 0 0 2%;
  margin-bottom: 2%;
}
#bio-page .bio-summary-section .image-container {
  height: 90%;
  margin: 0 auto;
}
#bio-page .bio-summary-section .image-container img {
  width: 100%;
}
#bio-page .bio-summary-section p {
  opacity: 0.8;
}
