h3 {
  color: #8d8d8d;
  text-align: center;
}
#top {
  text-align: center;
  padding: 3% 15%;
}
#top .col-xs-12 {
  margin-bottom: 1%;
}
#top button {
  margin-top: 2.5%;
}
#our-team {
  background-color: #f5f5f5;
  padding: 0 5% 4% 5%;
}
#our-team a {
  text-decoration: none;
}
#our-team h3 {
  margin: 3% auto;
}
#our-team .card {
  margin: 2% auto;
  width: 200px;
}
#our-team .card:hover > .image-container > .image-overlay {
  opacity: 0.7;
}
#our-team .card:hover > .image-container > .image-overlay > .text-holder > .bio-click {
  opacity: 1;
}
#our-team .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
#our-team .card .image-container img {
  width: 100%;
}
#our-team .card .image-container .image-overlay .text-holder {
  margin: 0 auto;
  height: 5em;
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
  text-align: center;
}
#our-team .card .image-container .image-overlay .text-holder .bio-click {
  font-family: "Nudista-bold", sans-serif;
  font-size: 24px;
  color: #fff;
  opacity: 0;
  width: 100%;
  position: relative;
  top: 50%;
  transform: perspective(1px) translateY(-50%);
}
#our-team .card .card-block {
  background-color: #2e373c;
  color: #fff;
  padding: 3% 0;
}
#our-team .card .card-block .card-text {
  font-size: 22px;
}
#our-team .card .card-block .card-title {
  font-size: 14px;
  opacity: 0.6;
}
#summary-content {
  padding: 5% 15%;
}
#summary-content img {
  width: 100%;
  margin-bottom: 2%;
}
#summary-content p {
  opacity: 0.6;
  font-size: 14px;
}
#quote-section {
  background: #f5f5f5;
  padding: 2% 15%;
}
#quote-section h2 {
  margin-bottom: 2%;
}
