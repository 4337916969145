#header-nav-desktop {
  min-height: 6em;
  background: #2e373c;
  border: 0;
  border-radius: 0px;
  position: relative;
  margin-bottom: 0;
}
@media (max-width: 1000px) {
  #header-nav-desktop {
    display: none;
  }
}
#header-nav-desktop .navbar-header .navbar-brand {
  height: 3em;
  padding: 6px 0;
}
#header-nav-desktop .navbar-header .navbar-brand .brand-image-container {
  height: 4em;
  width: 14em;
  background-size: cover;
  background-position: center;
}
#header-nav-desktop .main-section {
  border-color: #2e373c;
}
#header-nav-desktop .main-section .nav.navbar-nav .divider {
  height: 1em;
  width: 2px;
  background-color: #ffc600;
  margin: 7% 0 0 0;
}
#header-nav-desktop .main-section .nav.navbar-nav .divider.divider-option {
  margin: 0;
  position: relative;
  top: 25px;
}
#header-nav-desktop .main-section .nav.navbar-nav .active a {
  color: #ffc600;
}
#header-nav-desktop .main-section .nav.navbar-nav a {
  color: #fff;
  background: transparent;
  line-height: 2.5;
}
#header-nav-desktop .main-section .nav.navbar-nav a:hover {
  color: #ffc600;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right {
  width: 25em;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .login-li {
  line-height: 4;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .login-li .btn.btn-yellow {
  width: 9em;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .login-li .btn.btn-yellow i.fa {
  position: relative;
  top: 2px;
  left: -5px;
  font-size: 19px;
  color: #2e373c;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .nav-non-link {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #fff;
  line-height: 3;
  font-size: 14px;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .divider {
  height: 35px;
  width: 2px;
  background-color: #ffc600;
  margin: 4% 2% 0 1%;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right i {
  font-size: 15px;
  color: #267788;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .glyphicon {
  line-height: 2;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .glyphicon.glyphicon-remove {
  position: absolute;
  top: 17%;
  right: 25%;
  font-size: 20px;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .glyphicon.no-show {
  opacity: 0;
  z-index: 0;
}
#header-nav-desktop .main-section .nav.navbar-nav.navbar-right .glyphicon.show {
  opacity: 1;
  z-index: 100;
}
#header-nav-desktop #nav-search {
  background-color: #267788;
  position: absolute;
  top: 80px;
  z-index: -2;
  left: 0px;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
#header-nav-desktop #nav-search.show-nav {
  opacity: 1;
  z-index: 100;
}
#header-nav-desktop #nav-search.no-show-nav {
  opacity: 0;
  z-index: -2;
}
#header-nav-desktop #nav-search .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $dark-grey;
  border-radius: 0;
  box-shadow: none;
  --webkit-boxshadow: none;
  background: none;
  color: #fff;
}
#header-nav-desktop #nav-search .form-control:focus {
  border-bottom: 1px solid #ffc600;
}
#header-nav-mobile {
  height: 4em;
  background: #2e373c;
  border: 0;
  border-radius: 0px;
  position: relative;
  margin-bottom: 0;
  z-index: 9000;
}
@media (min-width: 1000px) {
  #header-nav-mobile {
    display: none;
  }
}
@media (max-width: 999px) {
  #header-nav-mobile .navbar-collapse.collapse {
    display: none !important;
  }
  #header-nav-mobile .navbar-collapse.collapse.in {
    display: block !important;
  }
  #header-nav-mobile .navbar-header .collapse,
  #header-nav-mobile .navbar-toggle {
    display: block !important;
  }
  #header-nav-mobile .navbar-header {
    float: none;
  }
  #header-nav-mobile .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
    position: relative;
    z-index: 1000;
  }
  #header-nav-mobile .navbar-nav>li {
    float: none;
    position: relative;
    z-index: 1300;
  }
  #header-nav-mobile .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
#header-nav-mobile .navbar-brand {
  float: none;
  padding: 3%;
  height: 3em;
}
#header-nav-mobile .navbar-brand .brand-image-container {
  height: 100%;
  width: 5em;
  margin: 0 auto;
}
#header-nav-mobile .navbar-brand .brand-image-container a {
  position: relative;
}
#header-nav-mobile .navbar-brand .brand-image-container a img {
  position: absolute;
  height: 8em;
  top: -48px;
  left: -4em;
}
@media (max-width: 623px) {
  #header-nav-mobile .navbar-brand .brand-image-container a img {
    top: -45px;
  }
}
#header-nav-mobile #mobile-nav {
  background-color: #267788;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1100;
}
#header-nav-mobile #mobile-nav .login-li {
  text-align: center;
}
#header-nav-mobile #mobile-nav p {
  color: #fff;
  font-size: 12px;
  text-align: center;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav .divider {
  height: 2px;
  width: 90%;
  background-color: #ffc600;
  margin: 2% auto;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav .active a {
  color: #ffc600;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav a {
  color: #fff;
  text-decoration: none;
  line-height: 2.5;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav a:hover {
  color: #ffc600;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav .inner-list {
  list-style-type: none;
  padding-left: 5%;
  position: relative;
  z-index: 2001;
}
#header-nav-mobile #mobile-nav .nav.navbar-nav .inner-list i {
  font-size: 24px;
  margin-right: 4%;
}
#header-nav-mobile .search-box {
  color: #fff;
  width: 90%;
}
#header-nav-mobile .search-box .glyphicon {
  line-height: 2;
  float: right;
  font-size: 25px;
}
#header-nav-mobile .search-box .glyphicon.glyphicon-remove {
  position: absolute;
  right: 25px;
  top: -3px;
  font-size: 30px;
}
#header-nav-mobile .search-box .glyphicon.no-show {
  opacity: 0;
  z-index: 0;
}
#header-nav-mobile .search-box .glyphicon.show {
  opacity: 1;
  z-index: 100;
}
#header-nav-mobile #nav-search-mobile {
  background-color: #267788;
  position: absolute;
  top: 55px;
  z-index: -1;
  left: 0px;
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
#header-nav-mobile #nav-search-mobile.show-nav {
  opacity: 1;
  z-index: 100;
}
#header-nav-mobile #nav-search-mobile.no-show-nav {
  opacity: 0;
}
#header-nav-mobile #nav-search-mobile .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $dark-grey;
  border-radius: 0;
  box-shadow: none;
  --webkit-boxshadow: none;
  background: none;
  color: #fff;
}
#header-nav-mobile #nav-search-mobile .form-control:focus {
  border-bottom: 1px solid #ffc600;
}
.navbar-toggle {
  float: none;
}
.navbar-toggle .icon-bar {
  width: 28px;
  background: #fff;
  transition: 0.5s ease;
}
.navbar-toggle .icon-bar:nth-child(2) {
  transform: rotate(-45deg) translateY(5px) translateX(-5px);
}
.navbar-toggle .icon-bar:nth-child(3) {
  opacity: 0;
}
.navbar-toggle .icon-bar:nth-child(4) {
  transform: rotate(45deg) translateY(-4px) translateX(-3px);
}
.navbar-toggle.collapsed .icon-bar:nth-child(2) {
  transform: rotate(0) translateY(0) translateX(0);
}
.navbar-toggle.collapsed .icon-bar:nth-child(3) {
  opacity: 1;
}
.navbar-toggle.collapsed .icon-bar:nth-child(4) {
  transform: rotate(0) translateY(0) translateX(0);
}
