#cat_container {
  background-color: #f5f5f5;
}
#cat_container h3 {
  color: #2e373c;
}
#cat_container ul {
  list-style: none;
}
#cat_container ul li span {
  padding: 0 2%;
}
