#loginModal h5 {
  background-color: #267788;
  height: 8px;
  width: 100%;
}
#loginModal .modal-header {
  padding: 0;
}
#loginModal .modal-body span {
  font-style: italic;
  font-size: 14px;
  color: #2e373c;
}
