.sidebar-top {
  background-color: #2e373c;
  color: #fff;
  text-align: left;
  padding: 2% 5% 6% 5%;
  width: 75%;
  margin: 8% auto 5% auto;
}
.sidebar-top .divider {
  width: 100%;
  height: 1px;
  margin: 2% auto;
  background: #ffc600;
}
.sidebar-top h5 {
  color: #267788;
  font-size: 18px;
}
.sidebar-top .more-about p,
.sidebar-top .cast-crew p {
  font-size: 18px;
  font-family: "Nudista-bold";
}
.sidebar-top .watch-online .divider {
  margin: 6% auto;
}
.sidebar-top .connect a {
  text-decoration: none;
  display: block;
  color: #fff;
  margin-bottom: 2%;
}
.sidebar-top .connect a div {
  display: inline-flex;
  margin-right: 1%;
}
.sidebar-bottom h3 {
  color: #8d8d8d;
  margin: 3% 0;
}
.sidebar-bottom a {
  text-decoration: none;
}
.sidebar-bottom a .card {
  width: 75%;
  margin: 0 auto 8% auto;
}
.sidebar-bottom a .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
.sidebar-bottom a .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.sidebar-bottom a .card .image-container img {
  width: 100%;
}
.sidebar-bottom a .card .card-block {
  background: #2e373c;
  padding: 2% 7%;
}
.sidebar-bottom a .card .card-block .card-text {
  color: #fff;
}
.sidebar-bottom a .card .card-block .card-date {
  color: #ffc600;
  font-size: 12px;
}
