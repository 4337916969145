.modal {
  text-align: center;
  padding: 0 !important;
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 400px;
  margin: 0 auto;
}
@media (max-width: 470px) {
  .modal-dialog {
    width: 90%;
  }
}
.modal-dialog .modal-content {
  border-radius: 0 !important;
  background-color: #f5f5f5;
}
.modal-dialog .modal-content .modal-header {
  border: none;
}
.modal-dialog .modal-content .modal-header span {
  position: relative;
  left: -5px;
  font-size: 20px;
  color: #267788;
}
.modal-dialog .modal-content .modal-header .close {
  opacity: 1;
}
.modal-dialog .modal-content .modal-body {
  padding: 0 8%;
}
.modal-dialog .modal-content .modal-body input::-webkit-input-placeholder {
  text-align: center;
}
.modal-dialog .modal-content .modal-body input::-webkit-input-placeholder :-moz-placeholder {
  text-align: center;
}
.modal-dialog .modal-content .modal-body input::-webkit-input-placeholder ::-moz-placeholder {
  text-align: center;
}
.modal-dialog .modal-content .modal-body input::-webkit-input-placeholder :-ms-input-placeholder {
  text-align: center;
}
.modal-dialog .modal-content .modal-body #login-header {
  margin-bottom: 6%;
}
.modal-dialog .modal-content .modal-body #login-header h2 {
  margin-bottom: 0;
  color: #2e373c;
}
.modal-dialog .modal-content .modal-footer {
  border: none;
  padding-top: 0;
}
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}
#or-header {
  overflow: hidden;
  text-align: center;
  color: #267788;
  margin: 0 auto 4% auto;
}
#or-header:before,
#or-header:after {
  background-color: #267788;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
#or-header:before {
  right: 0.5em;
  margin-left: -50%;
}
#or-header:after {
  left: 0.5em;
  margin-right: -50%;
}
