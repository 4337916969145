#search-form {
  margin: 4% auto;
}
#search-form h1 {
  color: #2e373c;
}
#search-form .search-posts h3 {
  color: #267788;
  margin: 4% 0 2% 0;
}
#search-form .search-posts a {
  text-decoration: none;
}
#search-form .search-posts a .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
#search-form .search-posts a .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
#search-form .search-posts a .card .image-container img {
  width: 100%;
}
#search-form .search-posts a .card .card-block {
  background: #2e373c;
  padding: 2% 7%;
}
#search-form .search-posts a .card .card-block .card-text {
  color: #fff;
}
#search-form .search-posts a .card .card-block .card-date {
  color: #ffc600;
  font-size: 12px;
}
#search-form .search-projects {
  margin: 1% auto;
}
#search-form .search-projects h3 {
  color: #267788;
  margin: 4% 0 2% 0;
}
#search-form .search-projects a {
  text-decoration: none;
}
#search-form .search-projects a .card:hover > .image-container > .image-overlay {
  opacity: 0.6;
}
#search-form .search-projects a .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
#search-form .search-projects a .card .image-container img {
  width: 100%;
}
#search-form .search-projects a .card .card-block {
  padding: 5%;
}
#search-form .search-projects a .card .card-block .card-title {
  font-family: "Nudista-semi-bold";
  font-size: 18px;
  margin-bottom: 2%;
}
#search-form .search-projects a .card .card-block .project-categories {
  margin-bottom: 2%;
}
#search-form .search-projects a .card .card-block .project-categories span {
  color: #ffc600;
  font-size: 12px;
}
