#dashboard_projects a {
  text-decoration: none;
}
#dashboard_projects .card .image-container {
  height: 100%;
  width: 100%;
  position: relative;
}
#dashboard_projects .card .image-container img {
  width: 100%;
}
#dashboard_projects .card .card-block {
  padding: 5%;
  min-height: 10em;
}
#dashboard_projects .card .card-block .card-title {
  font-family: "Nudista-semi-bold";
  font-size: 18px;
  margin-bottom: 2%;
  color: #fff;
  min-height: 3em;
}
#dashboard_projects .card .card-block .card-title:hover {
  color: #fff;
}
#dashboard_projects .card .card-block .project-categories {
  margin-bottom: 2%;
}
#dashboard_projects .card .card-block .project-categories span {
  color: #ffc600;
  font-size: 12px;
}
#dashboard_projects .card .card-block .project-categories-icons {
  padding-right: 6px;
}
#dashboard_projects .card .card-block .project-categories-icons .btn-yellow {
  z-index: 200;
  width: 2.2em;
  float: right;
}
#dashboard_projects .card .card-block .project-categories-icons .btn-yellow .glyphicon {
  font-size: 22px;
}
