@font-face {
  font-family: 'Nudista';
  src: url("../fonts/Nudista.eot"), url("../fonts/Nudista.eot?#iefix") format('embedded-opentype'), url("../fonts/Nudista.tff") format('truetype'), url("../fonts/Nudista.woff") format('woff');
}
@font-face {
  font-family: 'Nudista-semi-bold';
  src: url("../fonts/N1.eot"), url("../fonts/N1.eot?#iefix") format('embedded-opentype'), url("../fonts/N1.woff") format('woff');
}
@font-face {
  font-family: 'Nudista-bold';
  src: url("../fonts/N2.eot"), url("../fonts/N2.eot?#iefix") format('embedded-opentype'), url("../fonts/N2.woff") format('woff');
}
.c_icon {
  height: 1.75em;
  width: 1.75em;
  display: inline-block;
}
.c_icon.action {
  background: url("../icons/ACTION.svg");
  background-repeat: no-repeat;
}
.c_icon.adaptation {
  background: url("../icons/Adaptation.svg");
  background-repeat: no-repeat;
}
.c_icon.adventure {
  background: url("../icons/ADVENTURE.svg");
  background-repeat: no-repeat;
}
.c_icon.allages {
  background: url("../icons/ALLAGES.svg");
  background-repeat: no-repeat;
}
.c_icon.althistory {
  background: url("../icons/ALTHISTORY.svg");
  background-repeat: no-repeat;
}
.c_icon.comedy {
  background: url("../icons/COMEDY.svg");
  background-repeat: no-repeat;
}
.c_icon.comingofage {
  background: url("../icons/COMINGOFAGE.svg");
  background-repeat: no-repeat;
}
.c_icon.conspiracy {
  background: url("../icons/CONSPIRACY.svg");
  background-repeat: no-repeat;
}
.c_icon.crime {
  background: url("../icons/CRIME.svg");
  background-repeat: no-repeat;
}
.c_icon.drama {
  background: url("../icons/DRAMA.svg");
  background-repeat: no-repeat;
}
.c_icon.factual {
  background: url("../icons/FACTUAL.svg");
  background-repeat: no-repeat;
}
.c_icon.fantasy {
  background: url("../icons/FANTASY.svg");
  background-repeat: no-repeat;
}
.c_icon.horror {
  background: url("../icons/HORROR.svg");
  background-repeat: no-repeat;
}
.c_icon.kids {
  background: url("../icons/KIDS.svg");
  background-repeat: no-repeat;
}
.c_icon.military {
  background: url("../icons/MILITARY.svg");
  background-repeat: no-repeat;
}
.c_icon.monster {
  background: url("../icons/MONSTER.svg");
  background-repeat: no-repeat;
}
.c_icon.mystery {
  background: url("../icons/MYSTERY.svg");
  background-repeat: no-repeat;
}
.c_icon.noir {
  background: url("../icons/NOIR.svg");
  background-repeat: no-repeat;
}
.c_icon.period {
  background: url("../icons/PERIOD.svg");
  background-repeat: no-repeat;
}
.c_icon.political {
  background: url("../icons/POLITICAL.svg");
  background-repeat: no-repeat;
}
.c_icon.romance {
  background: url("../icons/ROMANCE.svg");
  background-repeat: no-repeat;
}
.c_icon.satire {
  background: url("../icons/SATIRE.svg");
  background-repeat: no-repeat;
}
.c_icon.scifi {
  background: url("../icons/SCIFI.svg");
  background-repeat: no-repeat;
}
.c_icon.superhero {
  background: url("../icons/SUPERHERO.svg");
  background-repeat: no-repeat;
}
.c_icon.supernatural {
  background: url("../icons/SUPERNATURAL.svg");
  background-repeat: no-repeat;
}
.c_icon.thriller {
  background: url("../icons/THRILLER.svg");
  background-repeat: no-repeat;
}
.c_icon.timetravel {
  background: url("../icons/TIMETRAVEL.svg");
  background-repeat: no-repeat;
}
.c_icon.western {
  background: url("../icons/WESTERN.svg");
  background-repeat: no-repeat;
}
.c_icon.ya {
  background: url("../icons/YA.svg");
  background-repeat: no-repeat;
}
.c_icon.zombie {
  background: url("../icons/ZOMBIE.svg");
  background-repeat: no-repeat;
}
.video-icon {
  height: 4em;
  width: 10em;
}
.video-icon.amazon {
  background: url("../images/amazon2.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #000;
}
.video-icon.google {
  background: url("../images/google.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.video-icon.itunes {
  background: url("../images/itunes.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.connect-icon {
  min-height: 1em;
  min-width: 1em;
}
.connect-icon.website {
  background: url("../icons/global-www.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.connect-icon.facebook {
  background: url("../icons/Facebook-article.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.connect-icon.twitter {
  background: url("../icons/Twitter-article.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.connect-icon.instagram {
  background: url("../icons/instagram-article.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
body {
  font-family: "Source Sans Pro", sans-serif;
}
body p,
body span {
  font-size: 16px;
}
body h1,
body h2,
body h3,
body h4,
body h5 {
  font-family: "Nudista-bold", "Source Sans Pro", sans-serif;
  color: #8d8d8d;
}
body h6 {
  font-family: "Nudaisata", "Source Sans Pro", sans-serif;
}
body .btn {
  font-family: "Nudista-semi-bold", "Source Sans Pro", sans-serif;
  font-size: 12px;
  padding: 0;
  width: 11.5em;
  height: 2em;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  line-height: 2.1;
  border: none;
  color: #fff;
}
body .btn.btn-db {
  background-color: #2e373c;
}
body .btn.btn-db:hover {
  background-color: #fff;
  color: #2e373c;
  border: 1px solid #2e373c;
}
body .btn.btn-yellow {
  background-color: #ffc600;
  color: #2e373c;
}
body .btn.btn-yellow:hover {
  background-color: #fff;
  color: #2e373c;
  border: 1px solid #2e373c;
}
body .btn.btn-lb {
  background-color: #267788;
}
body .btn.btn-lb:hover {
  background-color: #fff;
  color: #2e373c;
  border: 1px solid #2e373c;
}
body .btn.btn-lp {
  background-color: transparent;
  color: #5f5f5f;
  text-decoration: underline;
  font-style: italic;
}
body .btn.btn-lp:hover {
  color: #c8c8c8;
}
body .image-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background: #ffc600;
  opacity: 0;
}
body .center {
  text-align: center;
}
body .card {
  width: 90%;
  margin: 2% auto;
}
body .card img {
  height: 14em;
  width: 100%;
}
body .card .card-block {
  background-color: #2e373c;
  color: #fff;
  min-height: 2em;
}
body .addtoany_share_save_container a {
  margin: 3% 5% 0 0;
}
body .addtoany_share_save_container a span.a2a_svg {
  height: 35px !important;
  width: 35px !important;
}
body .connect a {
  font-size: 14px;
  -webkit-transition: font-size 0.3s ease-in-out;
  -moz-transition: font-size 0.3s ease-in-out;
  -o-transition: font-size 0.3s ease-in-out;
  -webkit-transition: font-size 0.3s ease-in-out;
  -moz-transition: font-size 0.3s ease-in-out;
  -ms-transition: font-size 0.3s ease-in-out;
  -o-transition: font-size 0.3s ease-in-out;
  transition: font-size 0.3s ease-in-out;
}
body .connect a:hover {
  font-size: 16px;
}
body .card.news-card .card-block {
  min-height: 12em;
  max-height: 14em;
}
body .card.news-card .card-block .card-text {
  max-height: 7em;
  overflow: hidden;
}
.no-padding {
  padding: 0;
}
.center {
  text-align: center;
}
