#contact-landing .image-container {
  margin: 2% auto;
  background-size: cover;
  background-position: center;
  height: 18.5em;
  width: 80%;
  background-color: #2e373c;
}
#contact-landing .more-info-section {
  background: #2e373c;
  padding: 3% 3% 20% 3%;
  width: 65%;
  margin: 3% auto;
}
#contact-landing .more-info-section p {
  color: #fff;
}
#contact-landing .more-info-section h5 {
  color: #267788;
}
#contact-landing .more-info-section .divider {
  width: 100%;
  height: 1px;
  margin: 2% auto;
  background: #ffc600;
}
#contact-landing .more-info-section .connect a {
  text-decoration: none;
  display: block;
  color: #fff;
  margin-bottom: 2%;
}
#contact-landing .more-info-section .connect a div {
  display: inline-flex;
  margin-right: 1%;
}
