#RegModal .modal-content {
  padding-bottom: 1em;
}
#RegModal h5 {
  background-color: #2e373c;
  height: 8px;
  width: 100%;
}
#RegModal .modal-header {
  padding: 0;
}
#RegModal .modal-header .close span {
  color: #2e373c;
}
#RegModal .modal-body {
  padding: 0 10%;
}
#RegModal .modal-body #reg-header {
  margin-bottom: 2em;
}
#RegModal .modal-body #reg-header h2 {
  color: #2e373c;
  margin-bottom: 0;
}
#RegModal .modal-body #reg-header span {
  font-size: 10px;
  font-style: italic;
}
#RegModal .modal-body .form-group {
  width: 100%;
  margin: 3% auto;
}
#RegModal .modal-body .help-block {
  font-size: 10px;
  font-style: italic;
  text-align: center;
}
#RegModal .modal-body .result-message {
  border: none;
  box-shadow: none;
}
