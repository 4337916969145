#about-home {
  background-color: #f5f5f5;
  color: #8d8d8d;
  text-align: center;
}
#about-home h4 {
  text-align: center;
  margin: 3% 0 2% 0;
}
#about-home .btn {
  margin: 3% 0;
}
